import ApeProject from "./list/Ape"
import RateProject from "./list/Rate"
import DYSCProject from "./list/Dysc"
import Mandala from "./list/Mandala"
import DeapSeaAngler from "./list/DeapSeaAngler"
import Vevue from "./list/Vevue"
// import ArtNftProject from "./list/ArtNft"
// import AvatarNftProject from "./list/AvatarNft"
// import VirtualWorldNftProject from "./list/VirtualWorldNft"

// const projects =[ApeProject,PolyientX,Other];
// const projects =[ApeProject,RateProject,ArtNftProject,AvatarNftProject,VirtualWorldNftProject];
const projects =[ApeProject,RateProject,DYSCProject,Mandala,DeapSeaAngler,Vevue];
// const projects =[ApeProject];

export const getProjects =(network)=>{
  let tmp_projects = [];
  projects.map((v)=>{
    if(v.status){
      if(typeof v.network[network] != "undefined" && v.network[network].status){
        tmp_projects.push(v);
      }
    }
  })
    return tmp_projects;
}

export const getVaults =(network)=>{
  let tmp_vaults = [];
  projects.map((v)=>{
    if(v.network[network].status){
      // tmp_vaults = [...tmp_vaults,...v.network[network].vaults];

      // new update
      v.network[network].vaults.map((va)=>{
        if(va.status){
         tmp_vaults.push(va);
        }
      })
    }
  })
  return tmp_vaults
}

export const getFrames =(network)=>{
  let tmp_frames = [];
  projects.map((v)=>{
    if(v.network[network].status){
      // tmp_frames = [...tmp_frames,...v.network[network].farms];

      // new update
      v.network[network].farms.map((fa)=>{
        if(fa.status){
          tmp_frames.push(fa);
        }
      })

    }
  })
  return tmp_frames
}

export const getFixRewards =(network)=>{
  let tmp_rewards = [];
  projects.map((v)=>{
    if(v.network[network].status){
      // tmp_frames = [...tmp_frames,...v.network[network].farms];
      // new update
      if(v.network[network].fixRewards){
        tmp_rewards.push(v.network[network].fixRewards)
      }
  
    }
  })
  return tmp_rewards
}
