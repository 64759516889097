import {DOMAIN_NAME} from "../../index"
const DYSCProject = {
  key:"dysc",  
  name:"Degen Yeti Ski Club",
  shortDetails:"Join the Degen Yeti Ski Club today!",
  details:"The Degen Yeti Ski Club (DYSC) is a collection of 8,888 NFTs living wild and free on the Avalanche blockchain. Each Yeti is a unique, digital collectible with over 50 different traits.",
  logo_image:DOMAIN_NAME+"/assets/images/projects/dysc/logo.svg",
  small_banner:DOMAIN_NAME+"/assets/images/projects/dysc/banner.png",
  big_banner:DOMAIN_NAME+"/assets/images/projects/dysc/big_banner.png",
  website:"https://yetiski.club/",
  twitter:"officialdysc",
  discord:"vbghzyXmcy",
  lite_paper:"",
  telegram:"",
  bg_color:"#ffdb3400",
  other_color:"#c9d4ed00",
  top_bg:"#f0f7fe",
  bottom_bg:"#00F0FF0D",
  total_vaults:10,
  total_farms:10,
  total_nfts: 100,
  network:{
    eth_prod:{ 
      vaults:[],
       farms:[],
      tokens:[],
      nfts:[],
      status:false
    },
    avalanche_prod:{
      vaults:[
        { 
           key:"dysc_1",
           perBlock:43200,
           name:"DYSC Vault (Closed)",
           p_key:"dysc",  
           website:"https://yetiski.club/",
           details:"THIS VAULT HAS OFFICIALLY CLOSED. PLEASE UNSTAKE ASAP AND MOVE TO THE NEWER VAULT.\n \n Degen Yeti Ski Club (DYSC) is a premium NFT collection living wild and free on the Avalanche blockchain. There are 8,888 unique pieces of art, containing more than 50 traits with a broad spectrum of rarity. Stake your Degen Yeti NFT and join the club to earn rewards in $DYSC token.",
           short_name:"",
           banner_img:DOMAIN_NAME+"/assets/images/projects/dysc/card.png",
           icon_img:DOMAIN_NAME+"/assets/images/projects/dysc/logo.svg",
           bg_color:"#c9d4ed00",
           other_color:"#f0f7fe",
           rewards_per_day: "",
           total_capacity:"8888",
           total_stake:"",
           my_rewards:"",
           internal_bonus:"12065",
           my_stake:"",
           my_nft:"0",
           myNfts:false,
           created_at:"1632459594",
           nft_name:"Degen Yeti Ski Club",
           nft_icon: DOMAIN_NAME+"/assets/images/projects/dysc/logo.svg",
           reward_address:"0x0BE255501ffDcEC412bce2e46514341727B68E47",
           reward_decimal:"18",
           reward_icon:DOMAIN_NAME+"/assets/images/projects/dysc/reward_icon.svg",
           reward_symbol:"DYSC",
           pool_id: 0,
           pool_nft_addr:"0x7acB68c3ff008D6b6dFa787D835Ef2D312E9e7E1",
           vault_addr: "0x54B7BD29273792b735459F01CDae2c3a3fAEcFb5",
           status:true
         },
         { 
          key:"dysc_2",
          off_chain:true,
          perBlock:43200,
          name:"DYSC Vault",
          p_key:"dysc",  
          website:"https://yetiski.club/",
          details:"Degen Yeti Ski Club (DYSC) is a premium NFT collection living wild and free on the Avalanche blockchain. There are 8,888 unique pieces of art, containing more than 50 traits with a broad spectrum of rarity. Stake your Degen Yeti NFT and join the club to earn rewards in $DYSC token.",
          short_name:"",
          banner_img:DOMAIN_NAME+"/assets/images/projects/dysc/card.png",
          icon_img:DOMAIN_NAME+"/assets/images/projects/dysc/logo.svg",
          bg_color:"#c9d4ed00",
          other_color:"#f0f7fe",
          rewards_per_day: "",
          total_capacity:"8888",
          total_stake:"",
          my_rewards:"",
          internal_bonus:"12065",
          my_stake:"",
          my_nft:"0",
          myNfts:false,
          created_at:"1632459594",
          nft_name:"Degen Yeti Ski Club",
          nft_icon: DOMAIN_NAME+"/assets/images/projects/dysc/logo.svg",
          reward_address:"0x0BE255501ffDcEC412bce2e46514341727B68E47",
          reward_decimal:"18",
          reward_icon:DOMAIN_NAME+"/assets/images/projects/dysc/reward_icon.svg",
          reward_symbol:"DYSC",
          pool_id: 1,
          pool_nft_addr:"0x7acB68c3ff008D6b6dFa787D835Ef2D312E9e7E1",
          vault_addr: "0x7acB68c3ff008D6b6dFa787D835Ef2D312E9e7E1",
          off_chain_id:"0x7acB68c3ff008D6b6dFa787D835Ef2D312E9e7E1",
          reward_claim_addr: "0xec48ac95781C745f9e7c5d7eC265d4D9Fa09152e",
          off_chain_type:"erc20",
          status:true
        }
       ],
      farms:[],
      tokens:[],
      nfts:[],
      status:true
    },
    polygon_prod:{
      vaults:[],
      farms:[],
      tokens:[],
      nfts:[],
      status:false
    },
    eth_dev:{ 
      vaults:[],
      farms:[ ],
      tokens:[],
      nfts:[],
      status:false
    },
    avalanche_dev:{
      vaults:[
        { 
           key:"dysc_1",
           perBlock:43200,
           name:"DYSC Vault",
           p_key:"dysc",  
           website:"https://yetiski.club/",
           details:"Degen Yeti Ski Club (DYSC) is a premium NFT collection living wild and free on the Avalanche blockchain. There are 8,888 unique pieces of art, containg more than 50 traits with a broad spectrum of rarity. Stake your Degen Yeti NFT and join the club to earn rewards in $DYSC token.",
           short_name:"",
           banner_img:DOMAIN_NAME+"/assets/images/projects/dysc/card.png",
           icon_img:DOMAIN_NAME+"/assets/images/projects/dysc/logo.svg",
           bg_color:"#c9d4ed00",
           other_color:"#f0f7fe",
           rewards_per_day: "",
           total_capacity:"8888",
           total_stake:"",
           my_rewards:"",
           internal_bonus:"12065",
           my_stake:"",
           my_nft:"0",
           myNfts:false,
           created_at:"1616516851",
           nft_name:"Fuji Degen Yeti Ski Club",
           nft_icon: DOMAIN_NAME+"/assets/images/projects/dysc/logo.svg",
           reward_address:"0x05415fDD505728D33Db2721b646d096494D8A0dA",
           reward_decimal:"18",
           reward_icon:DOMAIN_NAME+"/assets/images/projects/dysc/reward_icon.svg",
           reward_symbol:"FUJIY",
           pool_id: 1,
           pool_nft_addr:"0x1b4DEE2fd570a19a54Cf6CD4861308A571F0aE98",
           vault_addr: "0x94CefcE77317a5e35602cab79033788fafee40F0",
           status:true
         },
         { 
          key:"dysc_2",
          perBlock:43200,
          off_chain:true,
          name:"DYSC Off-chain",
          p_key:"dysc",  
          website:"https://yetiski.club/",
          details:"Degen Yeti Ski Club (DYSC) is a premium NFT collection living wild and free on the Avalanche blockchain. There are 8,888 unique pieces of art, containg more than 50 traits with a broad spectrum of rarity. Stake your Degen Yeti NFT and join the club to earn rewards in $DYSC token.",
          short_name:"",
          banner_img:DOMAIN_NAME+"/assets/images/projects/dysc/card.png",
          icon_img:DOMAIN_NAME+"/assets/images/projects/dysc/logo.svg",
          bg_color:"#c9d4ed00",
          other_color:"#f0f7fe",
          rewards_per_day: "",
          total_capacity:"8888",
          total_stake:"",
          my_rewards:"",
          internal_bonus:"12065",
          my_stake:"",
          my_nft:"0",
          myNfts:false,
          created_at:"1616516851",
          nft_name:"Fuji Degen Yeti Ski Club",
          nft_icon: DOMAIN_NAME+"/assets/images/projects/dysc/logo.svg",
          reward_address:"0x05415fDD505728D33Db2721b646d096494D8A0dA",
          reward_decimal:"18",
          reward_icon:DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
          reward_symbol:"FUJIY",
          pool_id: 0,
          pool_nft_addr:"0x1b4DEE2fd570a19a54Cf6CD4861308A571F0aE98",
          vault_addr: "0x1b4DEE2fd570a19a54Cf6CD4861308A571F0aE98",
          off_chain_id:"0x1b4DEE2fd570a19a54Cf6CD4861308A571F0aE98",
          reward_claim_addr: "0x481ABa6Fc47a6B1d718E6E59a6a0708347944115",
          status:true
        }
       ],
       farms:[ ],
       tokens:[],
       nfts:[],
       status:true
    },
    polygon_dev:{
      vaults:[],
      farms:[],
      tokens:[],
      nfts:[],
      status:false
    }
  },
  status:true
}

export default DYSCProject;
