import {DOMAIN_NAME} from "../../index"
const ApeProject = {
  key:"ape",  
  name:"Ape Island",
  shortDetails:"Ape Island is an experimental playground for NFTs, DeFi, and gaming.",
  details:"Ape Island is a primitive NFT world that is home to a series of consumer-friendly DeFi mini games & other exclusive experiences.",
  logo_image:DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
  small_banner:DOMAIN_NAME+"/assets/images/projects/ape/banner.png",
  big_banner:DOMAIN_NAME+"/assets/images/projects/ape/big_banner.png",
  website:"https://www.ape.in/",
  twitter:"ApeInFinance",
  discord:"zxsY34wJbe",
  lite_paper:"",
  telegram:"",
  bg_color:"#ffdb3447",
  other_color:"#00d1de47",
  top_bg:"#F2F3EF",
  bottom_bg:"#EEECDA",
  total_vaults:10,
  total_farms:10,
  total_nfts: 100,
  network:{
    eth_prod:{ 
      vaults:[
        { 
           key:"ape_1",
           perBlock:7200,
           name:" Ape Island Season 1",
           p_key:"ape",  
           website:"https://www.ape.in/",
           details:"This vault allows users to stake Ape Island Season 1 NFTs to earn APEIN token.",
           short_name:"",
           banner_img:DOMAIN_NAME+"/assets/images/projects/ape/card.png",
           icon_img:DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
           bg_color:"#ffdb348a",
           other_color:"#c9d4ed8a",
           rewards_per_day: "",
           total_capacity:"757",
           total_stake:"",
           my_rewards:"",
           internal_bonus:"12065",
           my_stake:"",
           my_nft:"0",
           myNfts:false,
           created_at:"1616516851",
           nft_name:"Ape Island Season 1",
           nft_icon: DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
           reward_address:"0x8bbf1dccbedd5c70d8e793d432fb56b848dd1698",
           reward_decimal:"18",
           reward_icon:DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
           reward_symbol:"APEIN",
           pool_id: 0,
           pool_nft_addr:"0x09b9905a472aa1d387c9c1d8d956aff5463837e8",
           vault_addr: "0xAA8aAD8f6718a18F7bC2fe8018da2540A4005085",
           status:true
         }, { 
           key:"ape_2",
           perBlock:7200,
           name:"Ape Island Season 2",
           p_key:"ape",  
           website:"https://www.ape.in/",
           details:"This vault allows users to stake Ape Island Season 2 NFTs to earn APEIN token.",
           short_name:"",
           banner_img:DOMAIN_NAME+"/assets/images/projects/ape/card.png",
           icon_img:DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
           bg_color:"#ffdb348a",
           other_color:"#c9d4ed8a",
           rewards_per_day: "",
           total_capacity:"1122",
           total_stake:"",
           my_rewards:"",
           internal_bonus:"12065",
           my_stake:"",
           my_nft:"0",
           myNfts:false,
           is_approved:false,
           created_at:"1616771106",
           nft_name:"Ape Island Season 2",
           nft_icon: DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
           reward_address:"0x8bbf1dccbedd5c70d8e793d432fb56b848dd1698",
           reward_decimal:"18",
           reward_icon:DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
           reward_symbol:"APEIN",
           pool_id: 1,
           pool_nft_addr:"0x5033973ea65c66a8745acdb4f8ecb326365de2be",
           vault_addr: "0xAA8aAD8f6718a18F7bC2fe8018da2540A4005085",
           status:true
         },
         { 
          key:"ape_3",
          perBlock:7200,
          name:" Ape Island Season 3",
          p_key:"ape",  
          website:"https://www.ape.in/",
          details:"This vault allows users to stake Ape Island Season 3 NFTs to earn APEIN token.",
          short_name:"",
          banner_img:DOMAIN_NAME+"/assets/images/projects/ape/card.png",
          icon_img:DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
          bg_color:"#ffdb348a",
          other_color:"#c9d4ed8a",
          rewards_per_day: "",
          total_capacity:"1000",
          total_stake:"",
          my_rewards:"",
          internal_bonus:"12065",
          my_stake:"",
          my_nft:"0",
          myNfts:false,
          created_at:"1633661687",
          nft_name:"Ape Island Season 3",
          nft_icon: DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
          reward_address:"0x8bbf1dccbedd5c70d8e793d432fb56b848dd1698",
          reward_decimal:"18",
          reward_icon:DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
          reward_symbol:"APEIN",
          pool_id: 2,
          pool_nft_addr:"0x1A96191bd388D5c0468E5205E80C96C95804B877",
          vault_addr: "0xAA8aAD8f6718a18F7bC2fe8018da2540A4005085",
          status:true
        },
        { 
          key:"ape_offspring",
          perBlock:7200,
          name:" Ape Island Offspring",
          p_key:"ape",  
          website:"https://www.ape.in/",
          details:"This vault allows users to stake Ape Island Offspring NFTs to earn APEIN token.",
          short_name:"",
          banner_img:DOMAIN_NAME+"/assets/images/projects/ape/card.png",
          icon_img:DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
          bg_color:"#ffdb348a",
          other_color:"#c9d4ed8a",
          rewards_per_day: "",
          total_capacity:"5000",
          total_stake:"",
          my_rewards:"",
          internal_bonus:"12065",
          my_stake:"",
          my_nft:"0",
          myNfts:false,
          created_at:"1633661687",
          nft_name:"Ape Island Offspring",
          nft_icon: DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
          reward_address:"0x8bbf1dccbedd5c70d8e793d432fb56b848dd1698",
          reward_decimal:"18",
          reward_icon:DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
          reward_symbol:"APEIN",
          pool_id: 3,
          pool_nft_addr:"0xcDeCE16fB4EF3c171E163B7C72023fFF4d3e2BD9",
          vault_addr: "0xAA8aAD8f6718a18F7bC2fe8018da2540A4005085",
          status:true
        }
       ],
       farms:[ { 
        key:"ape_1",
        perBlock:7200,
        website:"https://www.ape.in/",
        buy_url:"",
        name:"APEIN/ETH LP",
        p_key:"ape",  
        details:"This farm allows users to stake the APEIN/ETH LP token from SushiSwap to earn the APEIN token.",
        short_name:"LP Token",
        banner_img:DOMAIN_NAME+"/assets/images/tokens/ape_eth_banner.png",
        t1_name:"APEIN/ETH LP",
        t2_name:"Stake",
        t1_icon_img:DOMAIN_NAME+"/assets/images/tokens/px.svg",
        t2_icon_img:DOMAIN_NAME+"/assets/images/tokens/sushi.svg",
        token_decimal:18,
        bg_color:"#ffdb348a",
        other_color:"#c9d4ed8a",
        rewards_per_day: "",
        total_capacity:"",
        total_stake:"",
        my_rewards:"",
        internal_bonus:"12065",
        my_stake:"",
        my_tokens:"",
        myTokens:false,
        allowance:"0",
        created_at:"1628997250",
        token_name:"APEIN Token",
        token_icon: DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
        reward_address:"0x8bbf1DcCBEdD5c70d8E793d432fB56b848DD1698",
        reward_decimal:"18",
        reward_icon:DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
        reward_symbol:"APEIN",
        pool_id: 0,
        pool_token_addr:"0xa47bb4f34298052048c4d7319686e23064eb22d9",
        vault_addr: "0x4820D2708cc69Ae0bDc4820Bec6dfafA46634620",
        dex_name:"SushiSwap",
        dex_url:"https://app.sushi.com/add/ETH/0x8bbf1DcCBEdD5c70d8E793d432fB56b848DD1698",
        status:true
      }],
      tokens:[],
      nfts:[],
      status:true
    },
    avalanche_prod:{
      vaults:[{ 
        key:"ape_av_1",
        perBlock:43200,
        name:"Ape Island Avalanche",
        p_key:"ape",  
        website:"https://www.ape.in/",
        details:"This vault allows users to stake Ape Island Avalanche Expansion NFTs to earn the APEIN token on Avalanche.",
        short_name:"",
        banner_img:DOMAIN_NAME+"/assets/images/projects/ape/card.png",
        icon_img:DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
        bg_color:"#ffdb348a",
        other_color:"#c9d4ed8a",
        rewards_per_day: "",
        total_capacity:"5000",
        total_stake:"",
        my_rewards:"",
        internal_bonus:"12065",
        my_stake:"",
        my_nft:"0",
        myNfts:false,
        created_at:"1633755338",
        nft_name:"Ape Island Avalanche Expansion",
        nft_icon: DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
        reward_address:"0x938FE3788222A74924E062120E7BFac829c719Fb",
        reward_decimal:"18",
        reward_icon:DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
        reward_symbol:"APEIN",
        pool_id: 0,
        pool_nft_addr:"0x57E0879A663D07fAd0399bAFFf0C1f3c5D79d7FE",
        vault_addr: "0xd69dc82c70F27FF53614E0acdCf04958C5ac8183",
        status:true
      },
      { 
        key:"ape_av_2",
        off_chain:true,
        perBlock:43200,
        name:"OG Ape Island Season 1",
        p_key:"ape",  
        website:"https://www.ape.in/",
        details:"This vault allows users to stake OG Ape Island Season 1 NFTs to earn the APEIN token on Avalanche.",
        short_name:"",
        banner_img:DOMAIN_NAME+"/assets/images/projects/ape/card.png",
        icon_img:DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
        bg_color:"#ffdb348a",
        other_color:"#c9d4ed8a",
        rewards_per_day: "",
        total_capacity:"757",
        total_stake:"",
        my_rewards:"",
        internal_bonus:"12065",
        my_stake:"",
        my_nft:"0",
        myNfts:false,
        created_at:"1644650635",
        nft_name:"OG Ape Island Season 1",
        nft_icon: DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
        reward_address:"0x938FE3788222A74924E062120E7BFac829c719Fb",
        reward_decimal:"18",
        reward_icon:DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
        reward_symbol:"APEIN",
        pool_id: 1,
        pool_nft_addr:"0x1A96191bd388D5c0468E5205E80C96C95804B877",
        vault_addr: "0x1A96191bd388D5c0468E5205E80C96C95804B877",
        off_chain_id:"0x1A96191bd388D5c0468E5205E80C96C95804B877",
        reward_claim_addr: "0xec48ac95781C745f9e7c5d7eC265d4D9Fa09152e",
        off_chain_type:"erc20",
        status:true
      },
      { 
        key:"ape_av_3",
        off_chain:true,
        perBlock:43200,
        name:"OG Ape Island Season 2",
        p_key:"ape",  
        website:"https://www.ape.in/",
        details:"This vault allows users to stake OG Ape Island Season 2 NFTs to earn the APEIN token on Avalanche.",
        short_name:"",
        banner_img:DOMAIN_NAME+"/assets/images/projects/ape/card.png",
        icon_img:DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
        bg_color:"#ffdb348a",
        other_color:"#c9d4ed8a",
        rewards_per_day: "",
        total_capacity:"1122",
        total_stake:"",
        my_rewards:"",
        internal_bonus:"12065",
        my_stake:"",
        my_nft:"0",
        myNfts:false,
        created_at:"1644650635",
        nft_name:"OG Ape Island Season 2",
        nft_icon: DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
        reward_address:"0x938FE3788222A74924E062120E7BFac829c719Fb",
        reward_decimal:"18",
        reward_icon:DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
        reward_symbol:"APEIN",
        pool_id: 1,
        pool_nft_addr:"0x2B043C4Ceabd79Da68bc6B8Bf6db742EE234F100",
        vault_addr: "0x2B043C4Ceabd79Da68bc6B8Bf6db742EE234F100",
        off_chain_id:"0x2B043C4Ceabd79Da68bc6B8Bf6db742EE234F100",
        reward_claim_addr: "0xec48ac95781C745f9e7c5d7eC265d4D9Fa09152e",
        off_chain_type:"erc20",
        status:true
      },
      { 
        key:"ape_av_4",
        off_chain:true,
        perBlock:43200,
        name:"OG Ape Island Season 3",
        p_key:"ape",  
        website:"https://www.ape.in/",
        details:"This vault allows users to stake OG Ape Island Season 3 NFTs to earn the APEIN token on Avalanche.",
        short_name:"",
        banner_img:DOMAIN_NAME+"/assets/images/projects/ape/card.png",
        icon_img:DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
        bg_color:"#ffdb348a",
        other_color:"#c9d4ed8a",
        rewards_per_day: "",
        total_capacity:"267",
        total_stake:"",
        my_rewards:"",
        internal_bonus:"12065",
        my_stake:"",
        my_nft:"0",
        myNfts:false,
        created_at:"1644650635",
        nft_name:"OG Ape Island Season 3",
        nft_icon: DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
        reward_address:"0x938FE3788222A74924E062120E7BFac829c719Fb",
        reward_decimal:"18",
        reward_icon:DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
        reward_symbol:"APEIN",
        pool_id: 1,
        pool_nft_addr:"0x11a94554c18d2bffef260ca76013ba0da44c9076",
        vault_addr: "0x11a94554c18d2bffef260ca76013ba0da44c9076",
        off_chain_id:"0x11a94554c18d2bffef260ca76013ba0da44c9076",
        reward_claim_addr: "0xec48ac95781C745f9e7c5d7eC265d4D9Fa09152e",
        off_chain_type:"erc20",
        off_chain_type:"erc20",
        status:true
      },
      { 
        key:"ape_av_5",
        off_chain:true,
        perBlock:43200,
        name:"OG Ape Island Season Offspring",
        p_key:"ape",  
        website:"https://www.ape.in/",
        details:"This vault allows users to stake OG Ape Island Season Offspring NFTs to earn the APEIN token on Avalanche.",
        short_name:"",
        banner_img:DOMAIN_NAME+"/assets/images/projects/ape/card.png",
        icon_img:DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
        bg_color:"#ffdb348a",
        other_color:"#c9d4ed8a",
        rewards_per_day: "",
        total_capacity:"175",
        total_stake:"",
        my_rewards:"",
        internal_bonus:"12065",
        my_stake:"",
        my_nft:"0",
        myNfts:false,
        created_at:"1644650635",
        nft_name:"OG Ape Island Season Offspring",
        nft_icon: DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
        reward_address:"0x938FE3788222A74924E062120E7BFac829c719Fb",
        reward_decimal:"18",
        reward_icon:DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
        reward_symbol:"APEIN",
        pool_id: 1,
        pool_nft_addr:"0xf38bb73736267b23ce28adeb33972678f16532d9",
        vault_addr: "0xf38bb73736267b23ce28adeb33972678f16532d9",
        off_chain_id:"0xf38bb73736267b23ce28adeb33972678f16532d9",
        reward_claim_addr: "0xec48ac95781C745f9e7c5d7eC265d4D9Fa09152e",
        off_chain_type:"erc20",
        off_chain_type:"erc20",
        status:true
      }],
      farms:[{ 
        key:"ape_avalanche_farm_1",
        perBlock:43200,
        website:"https://www.ape.in/",
        buy_url:"",
        name:"APEIN/WAVAX LP Farm",
        p_key:"ape",  
        details:"This farm allows users to stake the APEIN/WAVAX LP token from TraderJoeXYZ platform to earn the APEIN tokens.",
        short_name:"JLP Token",
        banner_img:DOMAIN_NAME+"/assets/images/tokens/ape_avax_banner.png",
        default_eth_icon:"/assets/images/tokens/avalanche-avax-logo.png",
        t1_name:"APEIN/WAVAX LP",
        t2_name:"Stake",
        t1_icon_img:DOMAIN_NAME+"/assets/images/tokens/px.svg",
        t2_icon_img:DOMAIN_NAME+"/assets/images/tokens/sushi.svg",
        token_decimal:18,
        bg_color:"#ffdb348a",
        other_color:"#c9d4ed8a",
        rewards_per_day: "",
        total_capacity:"",
        total_stake:"",
        my_rewards:"",
        internal_bonus:"12065",
        my_stake:"",
        my_tokens:"",
        myTokens:false,
        allowance:"0",
        created_at:"1633883478",
        token_name:"APEIN Token",
        token_icon: DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
        reward_address:"0x938FE3788222A74924E062120E7BFac829c719Fb",
        reward_decimal:"18",
        reward_icon:DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
        reward_symbol:"APEIN",
        pool_id: 0,
        pool_token_addr:"0xB12AB345869B79BB9321f8e54A1193980D75E4AF",
        vault_addr: "0xcDeCE16fB4EF3c171E163B7C72023fFF4d3e2BD9",
        dex_name:"TraderJoeXYZ",
        dex_url:"https://www.traderjoexyz.com/#/pool/0x938fe3788222a74924e062120e7bfac829c719fb/0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7",
        status:true
      }],
      fixRewardsxx:{ 
        key:"fix_1",
        off_chain:true,
        name:"APEIN ETHEREUM TO AVALANCHE",
        p_key:"ape",  
        website:"https://www.ape.in/",
        details:"This is a one-time reward claim to migrate from Ethereum to Avalanche. Once claimed, you will no longer see the \"Claim Reward\" button here. If you have had multiple Ape In accounts, please login with each one separately to claim your allocated APEIN.",
        short_name:"",
        banner_img:DOMAIN_NAME+"/assets/images/projects/ape/card.png",
        icon_img:DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
        bg_color:"#ffdb348a",
        other_color:"#c9d4ed8a",
        rewards_per_day: "",
        total_capacity:"150",
        total_stake:"",
        my_rewards:"",
        internal_bonus:"12065",
        my_stake:"",
        my_nft:"0",
        myNfts:false,
        created_at:"1644650635",
        nft_name:"APEIN Token ",
        nft_icon: DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
        reward_address:"0x938FE3788222A74924E062120E7BFac829c719Fb",
        reward_decimal:"18",
        reward_icon:DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
        reward_symbol:"APEIN",
        pool_id: 1,
        pool_nft_addr:"0x938fe3788222a74924e062120e7bfac829c719fb",
        vault_addr: "0x938fe3788222a74924e062120e7bfac829c719fb",
        off_chain_id:"0x938fe3788222a74924e062120e7bfac829c719fb",
        reward_claim_addr: "0xec48ac95781C745f9e7c5d7eC265d4D9Fa09152e",
        off_chain_type:"erc20",
        status:true
      },
      fixRewards:{ 
        key:"fix_1",
        off_chain:true,
        name:"PGX Claim",
        p_key:"ape",  
        website:"https://www.polyient.games/",
        details:"This is a one-time reward claim for PGFK holders on Avalanche. Once claimed, you will no longer see the \"Claim Reward\" button here. If you have had multiple PGFK accounts, please login with each one separately to claim your allocated PGX.",
        short_name:"",
        banner_img:"",
        icon_img:"https://migrate.polyient.games/assets/images/token.svg",
        bg_color:"#cde4e53b",
        other_color:"#D4ECE9",
        rewards_per_day: "",
        total_capacity:"150",
        total_stake:"",
        my_rewards:"",
        internal_bonus:"12065",
        my_stake:"",
        my_nft:"0",
        myNfts:false,
        created_at:"1661186822",
        nft_name:"PGX Token ",
        nft_icon: "https://migrate.polyient.games/assets/images/token.svg",
        reward_address:"0xACEb6F4A053c95E919Af73D97DA87eda0cc23679",
        reward_decimal:"18",
        reward_icon:"https://migrate.polyient.games/assets/images/token.svg",
        reward_symbol:"PGX",
        pool_id: 1,
        pool_nft_addr:"0xDa1bF31d241A17159aE4d493CaADB7B8F8112511",
        vault_addr: "0xaceb6f4a053c95e919af73d97da87eda0cc23679",
        off_chain_id:"7",
        reward_claim_addr: "0xec48ac95781C745f9e7c5d7eC265d4D9Fa09152e",
        off_chain_type:"erc20",
        status:true
      },
      tokens:[],
      nfts:[],
      status:true
    },
    polygon_prod:{
      vaults:[],
      farms:[],
      tokens:[],
      nfts:[],
      status:false
    },
    eth_dev:{ 
      vaults:[
       { 
          key:"ape_1",
          perBlock:7200,
          name:" Ape Island Season 1",
          p_key:"ape",  
          website:"https://www.ape.in/",
          details:"This vault allows users to stake Ape Island Season 1 NFTs to earn APEIN token.",
          short_name:"",
          banner_img:DOMAIN_NAME+"/assets/images/projects/ape/card.png",
          icon_img:DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
          bg_color:"#ffdb348a",
          other_color:"#c9d4ed8a",
          rewards_per_day: "",
          total_capacity:"757",
          total_stake:"",
          my_rewards:"",
          internal_bonus:"12065",
          my_stake:"",
          my_nft:"0",
          myNfts:false,
          created_at:"1616516851",
          nft_name:"Ape Island Season 1",
          nft_icon: DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
          reward_address:"0x9ee60442eD89edA18F8ac8ACB296cC02aae0e4a3",
          reward_decimal:"18",
          reward_icon:DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
          reward_symbol:"APEIN",
          pool_id: 1,
          pool_nft_addr:"0x2f8238a59c1be49d8a963130a8fdf6784a63d825",
          vault_addr: "0x607c452263A1f07cD2db97f4326124903e2f7787",
          status:true
        }, { 
          key:"ape_2",
          perBlock:7200,
          name:"Ape Island Season 2",
          p_key:"ape",  
          website:"https://www.ape.in/",
          details:"This vault allows users to stake Ape Island Season 2 NFTs to earn APEIN token.",
          short_name:"",
          banner_img:DOMAIN_NAME+"/assets/images/projects/ape/card.png",
          icon_img:DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
          bg_color:"#ffdb348a",
          other_color:"#c9d4ed8a",
          rewards_per_day: "",
          total_capacity:"1122",
          total_stake:"",
          my_rewards:"",
          internal_bonus:"12065",
          my_stake:"",
          my_nft:"0",
          myNfts:false,
          is_approved:false,
          created_at:"1616771106",
          nft_name:"Ape Island Season 2",
          nft_icon: DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
          reward_address:"0x9ee60442eD89edA18F8ac8ACB296cC02aae0e4a3",
          reward_decimal:"18",
          reward_icon:DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
          reward_symbol:"APEIN",
          pool_id: 0,
          pool_nft_addr:"0x8f997273c88b61c4d554e2167621452c98deff5d",
          vault_addr: "0x607c452263A1f07cD2db97f4326124903e2f7787",
          status:true
        }, { 
          key:"ape_3",
          perBlock:7200,
          off_chain:true,
          name:"RinkApes Off-chain Mint",
          p_key:"ape",  
          website:"https://www.ape.in/",
          details:"This vault allows users to stake Ape Island Season 3 NFTs to earn APEIN token.",
          short_name:"",
          banner_img:DOMAIN_NAME+"/assets/images/projects/ape/card.png",
          icon_img:DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
          bg_color:"#ffdb348a",
          other_color:"#c9d4ed8a",
          rewards_per_day: "",
          total_capacity:"1122",
          total_stake:"",
          my_rewards:"",
          internal_bonus:"12065",
          my_stake:"",
          my_nft:"0",
          myNfts:false,
          is_approved:false,
          created_at:"1616771106",
          nft_name:"Ape Island Season 3",
          nft_icon: DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
          reward_address:"0x9ee60442ed89eda18f8ac8acb296cc02aae0e4a3",
          reward_decimal:"18",
          reward_icon:DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
          reward_symbol:"AIT1",
          pool_id: 0,
          pool_nft_addr:"0x40219f844363cefdf0523afe69f0a1be6cfa02d7",
          vault_addr: "0x607c452263A1f07cD2db97f4326124903e2f7787",
          off_chain_id:"0x40219f844363cefdf0523afe69f0a1be6cfa02d7",
          reward_claim_addr: "0x74b2ce346Bf2143eE9e5b40c8a4dFBFc2C582031",
          status:true
        }, { 
          key:"ape_4",
          perBlock:7200,
          off_chain:true,
          name:"RinkApes Off-chain Transfer",
          p_key:"ape",  
          website:"https://www.ape.in/",
          details:"This vault allows users to stake Ape Island Season 3 NFTs to earn APEIN token.",
          short_name:"",
          banner_img:DOMAIN_NAME+"/assets/images/projects/ape/card.png",
          icon_img:DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
          bg_color:"#ffdb348a",
          other_color:"#c9d4ed8a",
          rewards_per_day: "",
          total_capacity:"1122",
          total_stake:"",
          my_rewards:"",
          internal_bonus:"12065",
          my_stake:"",
          my_nft:"0",
          myNfts:false,
          is_approved:false,
          created_at:"1616771106",
          nft_name:"Ape Island Season 3",
          nft_icon: DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
          reward_address:"0x9ee60442ed89eda18f8ac8acb296cc02aae0e4a3",
          reward_decimal:"18",
          reward_icon:DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
          reward_symbol:"AIT1",
          pool_id: 0,
          pool_nft_addr:"0x151401488c29b03cef10e0c49250c3b76b2433c2",
          vault_addr: "0x607c452263A1f07cD2db97f4326124903e2f7787",
          off_chain_id:"0x151401488c29b03cef10e0c49250c3b76b2433c2",
          reward_claim_addr: "0x74b2ce346Bf2143eE9e5b40c8a4dFBFc2C582031",
          status:true
        }
      ],
      farms:[
        { 
          key:"ape_1",
          perBlock:7200,
          website:"https://www.ape.in/",
          buy_url:"",
          name:"APEIN/ETH LP",
          p_key:"ape",  
          details:"This farm allows users to stake the APEIN/ETH LP token from SushiSwap to earn the APEIN token.",
          short_name:"LP Token",
          banner_img:DOMAIN_NAME+"/assets/images/tokens/ape_eth_banner.png",
          t1_name:"APEIN/ETH LP",
          t2_name:"Stake",
          t1_icon_img:DOMAIN_NAME+"/assets/images/tokens/px.svg",
          t2_icon_img:DOMAIN_NAME+"/assets/images/tokens/sushi.svg",
          token_decimal:18,
          bg_color:"#ffdb348a",
          other_color:"#c9d4ed8a",
          rewards_per_day: "",
          total_capacity:"",
          total_stake:"",
          my_rewards:"",
          internal_bonus:"12065",
          my_stake:"",
          my_tokens:"",
          myTokens:false,
          allowance:"0",
          created_at:"1627715052",
          token_name:"APEIN Token",
          token_icon: DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
          reward_address:"0x9ee60442eD89edA18F8ac8ACB296cC02aae0e4a3",
          reward_decimal:"18",
          reward_icon:DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
          reward_symbol:"APEIN",
          pool_id: 4,
          pool_token_addr:"0xb392a7c5eedf80b7721ad9215237632a32124ba6",
          vault_addr: "0xb276a3Dc0A7cec9b79162112B0FC0ea7a40f1EF3",
          dex_name:"Polyient Dex",
          dex_url:"https://dex.polyient.games/",
          status:true
        }
      ],
      tokens:[],
      nfts:[],
      status:true
    },
    avalanche_dev:{
      vaults:[ { 
        key:"ape_avax_1",
        perBlock:7200,
        name:"MOCK ERC20 Vault",
        p_key:"ape",  
        website:"https://www.ape.in/",
        details:"This vault allows users to stake Ape Island Season 1 NFTs to earn APEIN token.",
        short_name:"",
        banner_img:DOMAIN_NAME+"/assets/images/projects/ape/card.png",
        icon_img:DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
        bg_color:"#ffdb348a",
        other_color:"#c9d4ed8a",
        rewards_per_day: "",
        total_capacity:"757",
        total_stake:"",
        my_rewards:"",
        internal_bonus:"12065",
        my_stake:"",
        my_nft:"0",
        myNfts:false,
        created_at:"1616516851",
        nft_name:"NFT Mock",
        nft_icon: DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
        reward_address:"0x5F870f8Fe826915E64070eF6d80a7F7Ae8cde7C3",
        reward_decimal:"18",
        reward_icon:DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
        reward_symbol:"RTC",
        pool_id: 1,
        pool_nft_addr:"0xbF90F426d36fe4B2D3e4a25F1BC78B1c97e4e3B9",
        vault_addr: "110",
        reward_claim_addr: "0x0b5378e1894F10De9e75bB57661f82e6EB844d7D",
        off_chain_id:"110",
        off_chain_type:"erc20",
        off_chain:true,
        status:true
      }, { 
        key:"ape_avax_2",
        perBlock:7200,
        name:"MOCK ERC21 Vault",
        p_key:"ape",  
        website:"https://www.ape.in/",
        details:"This vault allows users to stake Ape Island Season 1 NFTs to earn APEIN token.",
        short_name:"",
        banner_img:DOMAIN_NAME+"/assets/images/projects/ape/card.png",
        icon_img:DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
        bg_color:"#ffdb348a",
        other_color:"#c9d4ed8a",
        rewards_per_day: "",
        total_capacity:"757",
        total_stake:"",
        my_rewards:"",
        internal_bonus:"12065",
        my_stake:"",
        my_nft:"0",
        myNfts:false,
        created_at:"1616516851",
        nft_name:"NFT Mock",
        nft_icon: DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
        reward_address:"0x0389b3A9100ae3B60832a748cB0268b396797F72",
        reward_decimal:"1",
        reward_icon:DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
        reward_symbol:"RTC",
        pool_id: 1,
        pool_nft_addr:"0xbF90F426d36fe4B2D3e4a25F1BC78B1c97e4e3B9",
        vault_addr: "120",
        reward_claim_addr: "0x0b5378e1894F10De9e75bB57661f82e6EB844d7D",
        off_chain_id:"120",
        off_chain_type:"erc721",
        off_chain:true,
        status:true
      },{ 
        key:"ape_avax_3",
        perBlock:7200,
        name:"TEST MOCK ERC20 Vault - MINT",
        p_key:"ape",  
        website:"https://www.ape.in/",
        details:"This vault allows users to stake Ape Island Season 1 NFTs to earn APEIN token.",
        short_name:"",
        banner_img:DOMAIN_NAME+"/assets/images/projects/ape/card.png",
        icon_img:DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
        bg_color:"#ffdb348a",
        other_color:"#c9d4ed8a",
        rewards_per_day: "",
        total_capacity:"757",
        total_stake:"",
        my_rewards:"",
        internal_bonus:"12065",
        my_stake:"",
        my_nft:"0",
        myNfts:false,
        created_at:"1616516851",
        nft_name:"NFT Mock",
        nft_icon: DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
        reward_address:"0x5F870f8Fe826915E64070eF6d80a7F7Ae8cde7C3",
        reward_decimal:"18",
        reward_icon:DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
        reward_symbol:"RTC",
        pool_id: 1,
        pool_nft_addr:"0xbF90F426d36fe4B2D3e4a25F1BC78B1c97e4e3B9",
        vault_addr: "140",
        reward_claim_addr: "0x0b5378e1894F10De9e75bB57661f82e6EB844d7D",
        off_chain_id:"140",
        off_chain_type:"erc20",
        off_chain:true,
        status:true
      },{ 
        key:"ape_avax_4",
        perBlock:7200,
        name:"TEST MOCK ERC20 Vault - Transfer",
        p_key:"ape",  
        website:"https://www.ape.in/",
        details:"This vault allows users to stake Ape Island Season 1 NFTs to earn APEIN token.",
        short_name:"",
        banner_img:DOMAIN_NAME+"/assets/images/projects/ape/card.png",
        icon_img:DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
        bg_color:"#ffdb348a",
        other_color:"#c9d4ed8a",
        rewards_per_day: "",
        total_capacity:"757",
        total_stake:"",
        my_rewards:"",
        internal_bonus:"12065",
        my_stake:"",
        my_nft:"0",
        myNfts:false,
        created_at:"1616516851",
        nft_name:"NFT Mock",
        nft_icon: DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
        reward_address:"0x5F870f8Fe826915E64070eF6d80a7F7Ae8cde7C3",
        reward_decimal:"18",
        reward_icon:DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
        reward_symbol:"RTC",
        pool_id: 1,
        pool_nft_addr:"0xbF90F426d36fe4B2D3e4a25F1BC78B1c97e4e3B9",
        vault_addr: "150",
        reward_claim_addr: "0x0b5378e1894F10De9e75bB57661f82e6EB844d7D",
        off_chain_id:"150",
        off_chain_type:"erc20",
        off_chain:true,
        status:true
      },{ 
        key:"ape_avax_5",
        perBlock:7200,
        name:"Test MOCK ERC21 Vault - MINT",
        p_key:"ape",  
        website:"https://www.ape.in/",
        details:"This vault allows users to stake Ape Island Season 1 NFTs to earn APEIN token.",
        short_name:"",
        banner_img:DOMAIN_NAME+"/assets/images/projects/ape/card.png",
        icon_img:DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
        bg_color:"#ffdb348a",
        other_color:"#c9d4ed8a",
        rewards_per_day: "",
        total_capacity:"757",
        total_stake:"",
        my_rewards:"",
        internal_bonus:"12065",
        my_stake:"",
        my_nft:"0",
        myNfts:false,
        created_at:"1616516851",
        nft_name:"NFT Mock",
        nft_icon: DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
        reward_address:"0x0389b3A9100ae3B60832a748cB0268b396797F72",
        reward_decimal:"1",
        reward_icon:DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
        reward_symbol:"RTC",
        pool_id: 1,
        pool_nft_addr:"0xbF90F426d36fe4B2D3e4a25F1BC78B1c97e4e3B9",
        vault_addr: "160",
        reward_claim_addr: "0x0b5378e1894F10De9e75bB57661f82e6EB844d7D",
        off_chain_id:"160",
        off_chain_type:"erc721",
        off_chain:true,
        status:true
      },{ 
        key:"ape_avax_6",
        perBlock:7200,
        name:"Test MOCK ERC21 Vault - TRANSFER",
        p_key:"ape",  
        website:"https://www.ape.in/",
        details:"This vault allows users to stake Ape Island Season 1 NFTs to earn APEIN token.",
        short_name:"",
        banner_img:DOMAIN_NAME+"/assets/images/projects/ape/card.png",
        icon_img:DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
        bg_color:"#ffdb348a",
        other_color:"#c9d4ed8a",
        rewards_per_day: "",
        total_capacity:"757",
        total_stake:"",
        my_rewards:"",
        internal_bonus:"12065",
        my_stake:"",
        my_nft:"0",
        myNfts:false,
        created_at:"1616516851",
        nft_name:"NFT Mock",
        nft_icon: DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
        reward_address:"0x0389b3A9100ae3B60832a748cB0268b396797F72",
        reward_decimal:"1",
        reward_icon:DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
        reward_symbol:"RTC",
        pool_id: 1,
        pool_nft_addr:"0xbF90F426d36fe4B2D3e4a25F1BC78B1c97e4e3B9",
        vault_addr: "170",
        reward_claim_addr: "0x0b5378e1894F10De9e75bB57661f82e6EB844d7D",
        off_chain_id:"170",
        off_chain_type:"erc721",
        off_chain:true,
        status:true
      }],
      farms:[{ 
        key:"ape_avalanche_farm_1",
        perBlock:43200,
        website:"https://www.ape.in/",
        buy_url:"",
        name:"APEIN/WAVAX LP Farm",
        p_key:"ape",  
        details:"xx",
        short_name:"JLP Token",
        banner_img:DOMAIN_NAME+"/assets/images/tokens/ape_eth_banner.png",
        t1_name:"APEIN/WAVAX LP",
        t2_name:"Stake",
        t1_icon_img:DOMAIN_NAME+"/assets/images/tokens/px.svg",
        t2_icon_img:DOMAIN_NAME+"/assets/images/tokens/sushi.svg",
        token_decimal:18,
        bg_color:"#ffdb348a",
        other_color:"#c9d4ed8a",
        rewards_per_day: "",
        total_capacity:"",
        total_stake:"",
        my_rewards:"",
        internal_bonus:"12065",
        my_stake:"",
        my_tokens:"",
        myTokens:false,
        allowance:"0",
        created_at:"1628997250",
        token_name:"APEIN Token",
        token_icon: DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
        reward_address:"0x3BE34e752765B4C592d2Bf32675a1991a51692Be",
        reward_decimal:"18",
        reward_icon:DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
        reward_symbol:"APEIN",
        pool_id: 0,
        pool_token_addr:"0x4b2d840b66c81C583705d4a8e5933e298A9C84C9",
        vault_addr: "0x7B5fAF632Ec07bD5EEd8770d296ecAf3E5e79fdc",
        dex_name:"TraderJoeXYZ",
        dex_url:"https://www.traderjoexyz.com/#/pool/AVAX/0x3BE34e752765B4C592d2Bf32675a1991a51692Be",
        status:true
      }],
      fixRewards:{ 
        key:"fix_1",
        off_chain:true,
        name:"APEIN ETHEREUM TO AVALANCHE",
        p_key:"ape",  
        website:"https://www.ape.in/",
        details:"This is a one-time reward claim to migrate from Ethereum to Avalanche. Once claimed, you will no longer see the \"Claim Reward\" button here. If you have had multiple Ape In accounts, please login with each one separately to claim your allocated APEIN.",
        short_name:"",
        banner_img:DOMAIN_NAME+"/assets/images/projects/ape/card.png",
        icon_img:DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
        bg_color:"#ffdb348a",
        other_color:"#c9d4ed8a",
        rewards_per_day: "",
        total_capacity:"150",
        total_stake:"",
        my_rewards:"",
        internal_bonus:"12065",
        my_stake:"",
        my_nft:"0",
        myNfts:false,
        created_at:"1645788365",
        nft_name:"APEIN Token ",
        nft_icon: DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
        reward_address:"0x3be34e752765b4c592d2bf32675a1991a51692be",
        reward_decimal:"18",
        reward_icon:DOMAIN_NAME+"/assets/images/projects/ape/logo.svg",
        reward_symbol:"APEIN",
        pool_id: 1,
        pool_nft_addr:"0x3be34e752765b4c592d2bf32675a1991a51692be",
        vault_addr: "0x3be34e752765b4c592d2bf32675a1991a51692be",
        off_chain_id:"0x3be34e752765b4c592d2bf32675a1991a51692be",
        reward_claim_addr: "0xe0c2aa6011f07ac2420587cdc573999dc89cbb50",
        status:true
      },
      tokens:[],
      nfts:[],
      status:true
    },
    polygon_dev:{
      vaults:[],
      farms:[],
      tokens:[],
      nfts:[],
      status:false
    }
  },
  status:true
}

export default ApeProject;
